import { getShopItemStocksSetup, getShopItemStocksJson, deleteShopItemStocks, insertExcel, postShopItemStocksIdOnline, postShopItemStocksIdOffline } from '@/services/product'
import * as shopItemStocksApi from '@/services/shop-item-stocks'
import searchForm from '@/components/searchForm'
import importButton from '@/components/importButton'
import allButton from '@/components/allButton'
import createTable from '@/components/createTable'
import { mixin } from '@/mixins/mixin'
import jsonData from './jsonData.js'
import loading from '@/utils/loading.json'
export default {
  name: 'managerProduct',
  components: {
    searchForm,
    createTable,
    importButton,
    allButton
  },
  mixins: [mixin],
  data () {
    return {
      tableDataItem: jsonData.tableDataItem,
      insertExcel: insertExcel,
      shopItemStocksApi
    }
  },
  created () {
    this.queryData(getShopItemStocksSetup)
  },
  activated () {
    if (this.isActive) this.queryData(getShopItemStocksSetup)
    this.isActive = true
  },
  mounted () {
  },
  watch: {
    $route (to, from) {
      if (from.name === 'managerProductEdit') {
        this.$nextTick(() => {
          this.search()
          this.$refs.createTable.$refs.table.doLayout()
        })
      }
    }
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    // 点击操作按钮
    async handleClick (type, data) {
      if (type === 'create') {
        this.$router.push({
          name: 'goodsManagerVariationsAdd'
        })
      }
      if (type?.action === 'view') {
        this.$router.push({
          name: 'goodsManagerVariationsDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'goodsManagerVariationsEdit',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, deleteShopItemStocks)
      }
    },
    // 删除数据
    // deleteClick (data) {
    //   // mock操作
    //   this.$confirm('是否删除该数据?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.$store.state.app.loading = this.$loading(loading)
    //     deleteShopItemStocks(data.id.value).then(res => {
    //       this.$store.state.app.loading.close()
    //       this.$message({
    //         type: 'success',
    //         message: '删除成功!'
    //       })
    //       this.search()
    //     }).catch(() => this.$store.state.app.loading.close())
    //   }).catch(() => {
    //   })
    // },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getShopItemStocksJson(this.searchData).then(res => {
        if (res.data) {
          this.tableData = []
          this.$nextTick(() => {
            this.tableData = res.data.objects
            this.tabData = res.data.scopes
            Object.assign(this.pageData, res.data.pagination)
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 导出数据接口
    handleExport () {
      this.$refs.searchForm.commonOperate(shopItemStocksApi.getExport, (data) => {
        this.$router.push({
          name: 'goodsManagerVariationsDownloadCenter',
          params: { data }
        })
      }, this)
    },
    handleCreateTableSwitchChange (data) {
      const _this = this
      const { rowData, jsonItemData } = data
      if (jsonItemData.prop === 'online') {
        if (rowData[jsonItemData.prop].value) {
          _this.setGlobalLoading(true)
          postShopItemStocksIdOnline(rowData.id.value).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            _this.search()
          }).catch(() => {
            _this.setGlobalLoading(false)
            _this.search()
          })
        } else {
          _this.setGlobalLoading(true)
          postShopItemStocksIdOffline(rowData.id.value).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            _this.search()
          }).catch(() => {
            _this.setGlobalLoading(false)
            _this.search()
          })
        }
      }
    },
    forwardToPage (data, name) {
      if (name === 'shop_item') {
        this.$router.push({
          name: 'goodsManagerProductDetail',
          params: {
            id: data.id
          }
        })
      }
    }
  }
}
