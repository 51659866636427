export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '60'
  }, {
    prop: 'image',
    label: '',
    img: true,
    height: '100px',
    width: '160'
  }, {
    prop: 'shop_item',
    label: '',
    Object: 'value',
    width: '220'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '220'
  }, {
    prop: 'price',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'sold',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'locking_stock',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'stock',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'warning_stock',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'sku',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'online',
    label: '上架',
    Object: 'value',
    autoWidth: false,
    width: '80'
  }],
  dialogBasicData: [{
    collname: '基本信息',
    alterFormData: [{
      span: 24,
      label: '',
      group: [{
        name: '上架',
        prop: 'online'
      }],
      marginVisible: true,
      prop: 'checkList',
      type: 'checkGroup'
    }, {
      span: 6,
      label: '商铺',
      type: 'select',
      prop: 'gly',
      options: [{
        text: '1',
        value: false
      }, {
        text: '是',
        value: true
      }]
    }, {
      span: 6,
      label: '商品',
      type: 'select',
      prop: 'gly',
      options: [{
        text: '1',
        value: false
      }, {
        text: '是',
        value: true
      }]
    }, {
      span: 6,
      label: '中文名称',
      type: 'input',
      prop: 'chineseName'
    }, {
      span: 6,
      label: '英文名称',
      type: 'input',
      prop: 'englishName'
    }, {
      span: 6,
      label: 'SKU ID',
      type: 'input',
      prop: 'weChatID'
    }, {
      span: 6,
      label: '价格（元）',
      type: 'input',
      prop: 'weChatID'
    }, {
      span: 6,
      label: '成本价（元）',
      type: 'input',
      prop: 'weChatID'
    }, {
      span: 6,
      label: '划线价（元）',
      type: 'input',
      prop: 'weChatID'
    }, {
      span: 24,
      label: '图片',
      type: 'avatar',
      prop: 'IDFrontPhoto'
    }, {
      span: 6,
      label: '可销售库存',
      type: 'input',
      prop: 'IDFrontPhoto'
    }, {
      span: 6,
      label: '预警库存',
      type: 'input',
      prop: 'IDFrontPhoto'
    }, {
      span: 6,
      label: '锁定库存',
      type: 'input',
      prop: 'weChatID'
    }]
  }]
}
